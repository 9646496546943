import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    services: [
      {
        id: 1,
        name: 'Desarrollo de Software',
        shortDescription: 'Desarrollamos aplicaciones web y moviles, entre otras opciones',
        description: '<ul data-v-7cd368ae=""><li data-v-7cd368ae="">Aplicaciones y/o Paginas Web</li><li data-v-7cd368ae="">Aplicaciones Moviles o PWA</li><li data-v-7cd368ae="">API\'s</li><li data-v-7cd368ae="">Mejoras en software existente</li></ul>'
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
