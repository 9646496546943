<template lang="pug">
  #about.container.section.text-light
    //- h1.mb-5: b ¿Quienes somos?
    h2: b Estamos interesados en planear y desarrollar tu proyecto, incluso mejorarlo
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="scss" scoped>

</style>
