<template lang="pug">
  #welcome.d-flex.align-items-center.flex-column.flex-lg-row.justify-content-center
    .copy.text-light.flex-1
      .copy-logo.w-50.mx-auto.py-5
        Logo
      .copy-text.w-100.mx-auto.px-5.mb-4
        h1.mb-3
          b Hola, somos TenochLab.
        h4 Un laboratorio creativo orientado a software.
      //- vs-button.mx-auto(primary) Conocenos más
    .logo-wrapper.py-5.flex-1
      .illustration-wrapper.mx-auto
        img.img-fluid(src='@/assets/Illustrations/Saly-19.png')
</template>

<script>
import Logo from '../Logo.vue'

export default {
  name: 'Welcome',
  components: {
    Logo
  }
}
</script>

<style lang="scss" scoped>
$illustration-size: 75%;
$max-illustration-width: 600px;
$max-logo-width: 300px;

#welcome {
  min-height: 100vh;
  .illustration-wrapper {
    width: $illustration-size;
    max-width: $max-illustration-width;
  }
  .copy {
    text-align: center;
    .copy-logo {
      max-width: $max-logo-width;
    }
  }
}
</style>
