<template lang="pug">
  img(src="../assets/logo.png", alt="TenochLab Logo")
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="scss" scoped>
img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
</style>
