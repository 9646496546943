<template lang="pug">
  #home.bg-dark
    Welcome
    Nav
    .container.py-4
      vs-alert.text-left.d-block
        h4 Este sitio continua en contrucción
        h4.text-start.m-0 Pronto mostraremos nuestros proyectos, servicios y productos.
    //- About
    Services
    Footer
</template>

<script>
import About from '../components/Home/About.vue'
import Footer from '../components/Footer.vue'
import Nav from '../components/Shared/Nav.vue'
import Services from '../components/Home/Services.vue'
import Welcome from '../components/Home/Welcome.vue'

export default {
  name: 'Home',
  components: {
    About,
    Footer,
    Nav,
    Services,
    Welcome
  }
}
</script>
