<template lang="pug">
  #services.section.container.text-light
    h2.mb-4: b ¿Cómo te podemos ayudar?
    p.services-copy.mb-5 Siguiendo con nuestra misión, nuestros servicios están orientados a ayudar a nuestros clientes a crecer y mejorar.
    .services-list.d-flex.flex-wrap
      .service-card.col-12.py-3.px-4.d-flex.flex-wrap.align-items-center(
        v-for="service in services"
        :key="service.id"
      )
        .service-pic.col-4
          img.mb-4(src="@/assets/Illustrations/Saly-11.png")
        .service-body.pb-3.col-8.text-start.px-3
          .service-name.w-75.mb-3: h4.mb-0: b {{ service.name }}
          .service-description.mb-3(
            v-html="service.shortDescription"
          )
          //- .service-options
            vs-button Ver más
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Services',
  computed: {
    ...mapState(['services'])
  }
}
</script>

<style scoped lang="scss">
.service-card {
  .service-pic {
    z-index: 10;
    position: relative;
    left: 10px;
    img {
      max-width: 100%;
    }
  }
}
</style>
