<template lang="pug">
  nav.nav.sticky-top.py-4.px-4.glass.glass-border.d-block.mb-5
    .container.mx-auto.row.p-0
      .nav-brand.w-auto.p-0
        router-link(:to="{name: 'Home'}")
          Logo
      .nav-item.nav-link
        router-link
          //- Portfolio link
</template>

<script>
import Logo from '../Logo.vue'

export default {
  name: 'Nav',
  components: {
    Logo
  }
}
</script>

<style lang="scss" scoped>
$logo-size: 3rem;

.nav {
  .nav-brand {
    height: $logo-size;
  }
}
</style>
