<template lang="pug">
footer.footer.bg-pink.color-white.py-4
  .container
    p.mb-0
      | &copy; 2021 |
      span  TenochLab
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  // background: $pink;
}
</style>
